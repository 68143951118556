import React from 'react';
import './GoogleMap.css';

const GoogleMapIframe = () => {
  return (
    <div className="map-container">
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2622.6593882637144!2d18.04376787766935!3d48.90282817133832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714a1f4941dec75%3A0xc79dd71bcaa31e26!2sM-SPORT!5e0!3m2!1sen!2sat!4v1718062668095!5m2!1sen!2sat&t=k&z=3"
       
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default GoogleMapIframe;