import React from 'react';
import CardBase from './CardBase';
import './Cards.css';
import GoogleMapIframe from './GoogleMapIframe';
import './GoogleMap.css';

function CardsInfo() {
  return (
    <div className='cards'>
        <h1>Contacts & Location</h1>
        <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardBase 
                    label='Tournaments Director:'
                    text='Michal Matejka'
                    phone='Phone number: +421 903 645 790'
                    email='Email: michal.matejka9@gmail.com'
                    />
                    <CardBase
                    label='Contact Person:'
                    text='Jana Hliničanová'
                    phone='Phone number: +43 676 348 5683'
                    email='Email: sj.bedminton@gmail.com'
                    />
                    <CardBase
                    label='Venue:'
                    text='Športové centrum M-ŠPORT Ostrov 363, Zamarovce, Trenčín, Slovakia'
                    />
                </ul>
                <div className='cards__item'>
                    <div className='cards__item__base'>  
                        <GoogleMapIframe />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default CardsInfo;