import React from 'react';
import '../../App.css';
import './Tournaments.css';
import '../HeroSection.css';
import { Button } from '../Button';
import Footer from '../Footer';
import U15Info from '../U15Info';

export default function U15() {
  const handleButtonClick = (url, isDownload = false) => {
    if (isDownload) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop(); // Extracts the file name from the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const newWindow = window.open(url, '_blank');
      if (newWindow) newWindow.focus();
    }
  };

  return (
    <React.Fragment>
      <div className='u15'>
        <div className='hero-container_tournament'>
          <h1>Slovak Youth U15</h1>
          <span className='label deadline'>Entry deadline: 8th November</span>
          <p>22-24th November 2024</p>

          <div className='tournaments-btns'>
            <Button 
              className='btns'
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('/documents/SlovakYouthU15Invitation.pdf')}
            >
              INVITATION
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('documents/SlovakYouthU15EntryForm.xlsx', true)} // Use isDownload flag
            >
              REGISTER
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://www.tournamentsoftware.com')}
            >
              DRAWS
            </Button>
          </div>

          {/* <div className='tournaments-info-wrapper'>
            <div className='tournaments-info'>
            ⓘ Provisional times can be changed subject to the number of entries. 
            </div>
            <div className='tournaments-info'>
            Singles will be played in groups in first stage, then by KO system,
            doubles will be played by KO system.
            In the event of any dispute, the Referee´s decision shall be final.
            </div>
          </div> */}
          
        </div>
      </div>
      <U15Info />
      <Footer />
    </React.Fragment>
  );
}