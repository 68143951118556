import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [fbLogo, setFbLogo] = useState('images/logoFacebook.png'); // Initial state for Facebook logo
  const [igLogo, setIgLogo] = useState('images/logoInstagram.png'); // Initial state for Instagram logo

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleMouseEnterFB = () => setFbLogo('images/logoFacebookHover.png'); // Hover image for Facebook
  const handleMouseLeaveFB = () => setFbLogo('images/logoFacebook.png'); // Original image for Facebook

  const handleMouseEnterIG = () => setIgLogo('images/logoInstagramHover.png'); // Hover image for Instagram
  const handleMouseLeaveIG = () => setIgLogo('images/logoInstagram.png'); // Original image for Instagram

  const showButton = () => {
    if (window.innerWidth <= 1000) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    const handleResize = () => showButton();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
    <nav className='navbar'>
        <div className='navbar-container'>
            <Link to="/" className='navbar-logo' onclick={closeMobileMenu}>                                            {/*{Navbar logo*/}
                SLOVAK J&Y&nbsp; <img src='images/logo.png'  alt="Company logo"/>                                     {/* <i className='fa-solid fa-table-tennis-paddle-ball' /> */}
            </Link>
            <div className='menu-icon' onClick={handleClick}>                                {/*{Hambuger menu*/}
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              </div>
              
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>

              <li className='nav-item'>
                <Link to='/Junior' className='nav-links' onClick={closeMobileMenu}>               
                  Junior
                </Link>
              </li>

              <li className='nav-item'>
                <Link to='/U17' className='nav-links' onClick={closeMobileMenu}>              
                  U17
                </Link>
              </li>

              <li className='nav-item'>
                <Link to='/U15' className='nav-links' onClick={closeMobileMenu}>               
                  U15
                </Link>
              </li>

              <li className='nav-item'>
                <Link to='/U13' className='nav-links' onClick={closeMobileMenu}>              
                  U13
                </Link>
              </li>

              <li className='nav-item'>                                                     
              <a href='https://slovakia.travel/en/trencin' className='nav-links' target="_blank" rel="noopener noreferrer"  onClick={closeMobileMenu}>
                  Trenčín
              </a>
              </li>

              <li className='nav-item' onClick={closeMobileMenu}>
              <a href='https://www.instagram.com/slovakbadminton?igsh=NTNiZG9odDkxZ3Nq' 
              className='nav-links' 
              target="_blank" 
              rel="noopener noreferrer"
              onMouseEnter={handleMouseEnterIG} 
              onMouseLeave={handleMouseLeaveIG}>
              <img src={igLogo} alt="Instagram logo" />
              </a>
            </li>
            
            <li className='nav-item' onClick={closeMobileMenu}>
              <a href='https://www.facebook.com/slovenskyzvazbedmintonu/?fref=ts' className='nav-links' target="_blank" rel="noopener noreferrer"
                 onMouseEnter={handleMouseEnterFB} onMouseLeave={handleMouseLeaveFB}>
                <img src={fbLogo} alt="Facebook logo" />
              </a>
            </li>

            <li className='nav-item'>
                <Link to='/info' className='nav-links-mobile' onClick={closeMobileMenu}>              
                  INFO
                </Link>
              </li>     

            </ul>
            {button && <Button buttonStyle='btn--outline' linkTo='/info'>INFO</Button>}

        </div>
    </nav>
    </React.Fragment>
  );
}

export default Navbar;