import React from 'react';
import CardTournamentInfo from './CardTournamentInfo';
import './Cards.css';
import './HeroSection.css'

function U15Info() {
  return (
    <div>
      <div className='cards'>
        <h1>Tournament information</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardTournamentInfo 
                label1='Entry fees:'
                text1='Singles = 20€'
                text2='Doubles = 15€ per player'
                text5='Entry fees have to be paid by bank transfer before the event or in
                      cash (EUR) to the organizer during the event.'
              />
              <CardTournamentInfo 
                label1='Offcial brand of shuttles:'
                text3='KAWASAKI SHUTTLECOCK TEAM 1'
                text4='2 shuttles will be provided per match, afterwards the participants
                      will supply shuttles by themselves. KAWASAKI SHUTTLECOCK TEAM 1 can be acquired from the organisers.'
              />
            </ul>

            <div className='table-wrapper'>
            <table className='table'>
              <thead>
                <tr>
                  <th>
                    22nd November
                    <span className='label time'>8:30</span>
                  </th>
                  <th>
                    23rd November
                    <span className='label time'>8:30</span>
                  </th>
                  <th>
                    24th November
                    <span className='label time'>8:30</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>   {/* first row */}
                  <td> {/* first column - Friday */}
                    <span className='label size'>Main event</span>   
                  </td>
                  <td> {/* second column - Saturday */}
                    <span className='label size'>Main event</span> 
                  </td>
                  <td> {/* third column - Sunday */}
                    <span className='label size'>SF</span> 
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>
                  </td>
                </tr>
                <tr>   {/* second row */}
                <td>-</td> {/* first column - Friday */}
                  <td> {/* second column - Saturday */}
                    <span className='label size'>SF</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WD</span>
                    <span className='label discipline'>MD</span>
                  </td>
                  <td> {/* third column - Sunday */}
                    <span className='label size'>Finals</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WD</span>
                    <span className='label discipline'>MD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
            <ul className='cards__items'>
            <CardTournamentInfo 
              text3="ⓘ Provisional times can be changed subject to the number of entries.
              The final frame schedule (with the exact planned conclusion of all
              matches) shall be made at least 20 days before the start of the
              tournament and shall be published on the organiser's website."
              text4='Singles will be played in groups in first stage, then by KO system,
              doubles will be played by KO system.
              In the event of any dispute, the Referee´s decision shall be final.'
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default U15Info;