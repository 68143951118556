import React from 'react';
import CardBaseOtherServices from './CardBaseOtherServices';
import './Cards.css';

function OtherServices() {
  return (
    <div>
      <div className='cards__next'>
        <h1>Other Services</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardBaseOtherServices 
                label='Stringing:'
                text='Available all the time during the event:'
                price1='Stringing only = 10€'
                price2='Stringing + new string = 18€'
              />
              <CardBaseOtherServices 
                label='Meals:'
                text='Restaurant is a part of the venue. Lunch and dinner are provided in the form 
                      of buffet offering variety of meals – all you can eat:'
                price1='Lunch = 11€'
                price2='Dinner = 11€'
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherServices;