import React, { useEffect } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection({ carouselRef }) {  // Accept carouselRef as a prop from the parent
  useEffect(() => {
    const video = document.getElementById('hero-video');
    if (video) {
      video.play().catch((error) => {
        console.error('Autoplay was prevented:', error);
      });
    }
  }, []);

  // Function to scroll to the target section
  const scrollToCarousel = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className='hero-container'>
      <video
        id="hero-video"
        src="/videos/video-5.mp4"
        autoPlay
        loop
        muted
        playsInline
        poster="/images/video-51.jpg"
        preload="metadata"
      ></video>
      <h1>Slovak Junior & Youth</h1>
      <p>Experience badminton tournaments in beautiful city of Trenčín</p>
      
      <div className='hero-btns'>
        <Button 
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          linkTo='/info'
        >
          INFO
        </Button>
        <Button 
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={scrollToCarousel}  // Use the passed ref to scroll to the Carousel
        >
          SEE PHOTOS <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;