import React from 'react';
import './Cards1.css';

function CardImageHorizontal(props) {
  return (
  <React.Fragment>
    <li className='cards__item__horizontal1' >
      <div className='cards__item__link1'>
        <figure className='cards__item__pic-wrap1'data-category={props.tag}>
          <img 
            className='cards__item__img1'
            alt="Travel" 
            src={props.src}
          />
        </figure>
        <figure className='cards__item__info1'>
          <h4 className='cards__item__base__label1'>{props.label}</h4>
          <h4 className='cards__item__base__text__italics__margin1'>{props.address}</h4>
          <h5 className='cards__item__base__text1'>{props.price1}</h5>
          <h5 className='cards__item__base__text1'>{props.price2}</h5>
          <h5 className='cards__item__base__text__margin1'>{props.price3}</h5>
          <h5 className='cards__item__base__text__margin1'>{props.description}</h5>
          <h5 className='cards__item__base__text1'>{props.distance}</h5>
        </figure>
      </div>
    </li>
  </React.Fragment>
  );
}

export default CardImageHorizontal;