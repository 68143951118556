import React from 'react';
import './Cards.css';

function CardBaseTransport(props) {
  return (
    <React.Fragment>
      <li className='cards__item'>
        <div className='cards__item__base'>
          <div className='cards__item__base__info'>
            <h5 className='cards__item__base__text__margin'>{props.text1}</h5>
            <h4 className='cards__item__base__label'>{props.label1}</h4>
            <h4 className='cards__item__base__label'>{props.label2}</h4>
            <h5 className='cards__item__base__text__margin'>{props.text2}</h5>
            <h5 className='cards__item__base__text'>{props.text3}</h5>
          </div>
        </div>
      </li>   
    </React.Fragment>
  );
}

export default CardBaseTransport;