import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './Carousel.css';

const Carousel = () => {
  useEffect(() => {
    new Swiper('.tranding-slider', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      loop: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
      },
    });
  }, []);

  return (
    <section id="tranding">
      <div className="carousel-container">
        <div className="swiper tranding-slider">
          <div className="swiper-wrapper">
            {/* Slide-start */}
            <div className="swiper-slide tranding-slide">
              <div className="tranding-slide-img">
                <img src="images/foto-1.jpeg" alt="Tranding" />
              </div>
            </div>
            {/* Slide-end */}
            {/* Repeat for other slides */}
            {/* Slide-start */}
            <div className="swiper-slide tranding-slide">
              <div className="tranding-slide-img">
                <img src="images/foto-2.jpeg" alt="Tranding" />
              </div>
            </div>
            {/* Slide-end */}
            {/* Slide-start */}
            <div className="swiper-slide tranding-slide">
              <div className="tranding-slide-img">
                <img src="images/foto-3.jpeg" alt="Tranding" />
              </div>
            </div>
            {/* Slide-end */}
            {/* Slide-start */}
            <div className="swiper-slide tranding-slide">
              <div className="tranding-slide-img">
                <img src="images/foto-4.jpeg" alt="Tranding" />
              </div>
            </div>
            {/* Slide-end */}
            {/* Slide-start */}
            <div className="swiper-slide tranding-slide">
              <div className="tranding-slide-img">
                <img src="images/foto-5.jpeg" alt="Tranding" />
              </div>
            </div>
            {/* Slide-end */}
            {/* Slide-start */}
            <div className="swiper-slide tranding-slide">
              <div className="tranding-slide-img">
                <img src="images/foto-6.jpeg" alt="Tranding" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;