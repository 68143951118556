import React from 'react';
import './Cards.css';

function CardBaseOtherServices(props) {
  return (
    <React.Fragment>
      <li className='cards__item'>
        <div className='cards__item__base'>
          <div className='cards__item__base__info'>
            <h4 className='cards__item__base__label'>{props.label}</h4>
            <h5 className='cards__item__base__text__margin'>{props.text}</h5>
            <h5 className='cards__item__base__text'>{props.price1}</h5>
            <h5 className='cards__item__base__text'>{props.price2}</h5>
          </div>
        </div>
      </li>   
    </React.Fragment>
  );
}

export default CardBaseOtherServices;