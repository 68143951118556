import React from 'react';
import CardInfo from './CardBaseTransport';
import './Cards.css';

function Transport() {
  return (
    <div>
      <div className='cards__next'>
        <h1>Transport</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardInfo 
                text1='Organizer provides a transport for a fee from:'
                label1='BRATISLAVA International Airport = 30€/person/one way'
                label2='VIENNA International Airport = 40€/person/one way'
                text2='Send your travel details to sj.bedminton@gmail.com as soon as possible. 
                      Transport fees have to be paid by bank transfer before the event or in cash 
                      (EUR) to the organizer during the event.'
                text3='All official hotels are walking distance to the venue and therefore
                      transport between venue and hotels is not provided.'
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transport;