import React, { useRef } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Carousel from '../Carousel';
import Footer from '../Footer';

function Home() {
  const carouselSectionRef = useRef(null);  // Define the ref here

  return (
    <React.Fragment>
      <HeroSection carouselRef={carouselSectionRef} />  
      <Cards />
      <div ref={carouselSectionRef} id="carousel-section"><Carousel /></div>
      <Footer />
    </React.Fragment>
  );
}

export default Home;