import React from 'react'
// import { Button } from './Button'
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer () {
  return (
    <div className='footer-container'>
        {/* <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
                Sign up to our newsletter to receive information about upcoming tournaments and never miss out.
            </p>
            <p className='footer-subscription-text'>
                You can unsubscribe at any time.
            </p>
            <div className='input-areas'>
                <form>
                    <input type='email' name='email' placeholder='Your email' className='footer-input'/>
                    <Button buttonStyle='btn--outline'>Subscribe</Button>
                </form>
            </div>
        </section>
        <div className='footer-link'>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>About Us</h2>
                    <Link to='/sign-up'>How it works</Link>
                    <Link to='/'>Slovak Badminton Fedaration</Link>
                    <Link to='/'>FZ Forza</Link>
                    <Link to='/'>Sponzors</Link>
                    <Link to='/'>Terms of Service</Link>
                </div>
                <div className='footer-link-items'>
                    <h2>Contact Us</h2>
                    <Link to='/sign-up'>How it works</Link>
                    <Link to='/'>Slovak Badminton Fedaration</Link>
                    <Link to='/'>FZ Forza</Link>
                    <Link to='/'>Sponzors</Link>
                    <Link to='/'>Terms of Service</Link>
                </div>
            </div>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>About Us</h2>
                    <Link to='/sign-up'>How it works</Link>
                    <Link to='/'>Slovak Badminton Fedaration</Link>
                    <Link to='/'>FZ Forza</Link>
                    <Link to='/'>Sponzors</Link>
                    <Link to='/'>Terms of Service</Link>
                </div>
                <div className='footer-link-items'>
                    <h2>About Us</h2>
                    <Link to='/sign-up'>How it works</Link>
                    <Link to='/'>Slovak Badminton Fedaration</Link>
                    <Link to='/'>FZ Forza</Link>
                    <Link to='/'>Sponzors</Link>
                    <Link to='/'>Terms of Service</Link>
                </div>
            </div>
        </div> */}
        <section className='social-media'>
            <div className='social-media-wrap'>

                <div className='footer-logo'>
                < a href='https://www.badmintoneurope.com/Cms/' target='_blank' rel='noopener noreferrer' className='social-logo'>
                <img src='images/logoJuniorYellow.png' alt="BWF Junior logo" />
                </a>
                </div>

                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                         <img src='images/logoU17Yellow.png'  alt="BWF Junior logo"/> 
                    </Link>
                </div>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                         <img src='images/logoKawasaki.png'  alt="BWF Junior logo"/> 
                    </Link>
                </div>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                         <img src='images/logoSzbe.png'  alt="BWF Junior logo"/> 
                    </Link>
                </div>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                         <img src='images/logoAS.png'  alt="BWF Junior logo"/> 
                    </Link>
                </div>
            </div>
        </section>

        <div className='info-footer'>
          © Slovak Junior & Youth 2024
        </div>

    </div>
    
  )
}

export default Footer
